import jsPDF from 'jspdf';

// creating a function to install MoodBoard pdf file
export function saveMBpdf(Grid, MoodBoardName) {
  let height = Grid.offsetHeight;
  let width = Grid.offsetWidth;
  let aspectRatio = width/height;
  let pageSides;

  if (aspectRatio >= 1) {
    pageSides = 'l';
  } else if (aspectRatio < 1) {
    pageSides = 'p';
  }
  
  const widthOnePercent = width / 100;

  const marginConst = widthOnePercent;

  if (Grid) { 

    const doc = new jsPDF(pageSides, 'px', [width + marginConst, height + marginConst]);

    Array.from(Grid.children).forEach((elem) => {
      console.log(elem)
      const imageElement = elem.querySelector('.switch-photo,.grid-item')
      if (imageElement) {
        const topPosition = Number(elem.style.top.replace('px', ''))
        const leftPosition = Number(elem.style.left.replace('%', '') * widthOnePercent)
        
        const imageWidth = imageElement.clientWidth;
        const imageHeight = imageElement.clientHeight;
        doc.addImage(
          imageElement.src,
          'JPEG',
          (leftPosition == 0 ? marginConst : leftPosition),
          (topPosition == 0 ? marginConst : topPosition),
          (leftPosition == 0 ? imageWidth-marginConst : imageWidth),
          (topPosition == 0 ? imageHeight-marginConst : imageHeight)
        )
      }
    })

    if (MoodBoardName == undefined || MoodBoardName == 'Untitled board') {
      doc.save('MoodBoard');
    } else {
      doc.save(MoodBoardName);
    }
  }
}
