import React from "react"
import { useState, useEffect, useRef } from 'react'
import Packery from "packery"
import Draggabilly from 'draggabilly'
import { PreviewLoadingScreen } from './loader'
import CommentIcon from '../images/comment.svg';


// Creating photos in saved MB
function CreatePhotosInGrid(props) {

    const isGridInitialized = useRef(false);

    const isPhotosReloaded = useRef(false);
    
    const packery = useRef();

    function initializePackery() {
        const pckry = new Packery(props.preview.current, {
            itemSelector: '.grid-item-cont',
            columnWidth: '.preview-grid-column',
            gutter: '.preview-grid-gutter',
            transitionDuration: 0,
            originTop: true,
            percentPosition: true
        });
        pckry.layout();

        packery.current = pckry;

        isGridInitialized.current = true;
    };

    useEffect(() => {
        if (!isGridInitialized.current && props.previewMoodBoard) {
            initializePackery()
        }
    }, [props.previewMoodBoard])

    const isBoardFitInHeight = useRef(false);

    function getHeight() {

        if (props.preview.current) {

            const previewGridHeight = document.querySelector('.preview-grid').scrollHeight;
    
            const rootHeight = document.querySelector('#root').clientHeight;
            
            let difference = (rootHeight / previewGridHeight).toFixed(2);
    
            let secondParam = 1;
    
            if (difference < 1) {
                secondParam = 0.8;
            };
    
            if (isGridInitialized.current) {
                if (difference < 1) {
                    document.querySelector('.preview-grid').style.width = `${difference * 100 * secondParam}%`;
                    isBoardFitInHeight.current = true;
                } else if (difference >= 1) {
                    document.querySelector('.preview-grid').style.width = `100%`;
                    isBoardFitInHeight.current = false;
                }
            }
            
            packery.current.layout();
    
            return isBoardFitInHeight.current
        }

    };

    function changeScaleOfBoard(scale) {

        const previewGrid = document.querySelector('.preview-grid');
        const widthInPixels = previewGrid.offsetWidth;
        const parentWidth = previewGrid.parentElement.offsetWidth;
        const widthInPercentage = ((widthInPixels / parentWidth) * 100);

        if (scale == 'minus' && widthInPercentage >= 25) {
            previewGrid.style.width = `${widthInPercentage-5}%`
        } else if (scale == 'plus') {
            previewGrid.style.width = `${widthInPercentage+5}%`
        }

        packery.current.layout()
    
    }

    useEffect(() => {
        props.setAutoScaleFunc.current = getHeight
        props.setScaleFunc.current = changeScaleOfBoard
    }, [])

    
    if (packery.current) {

        packery.current.off('layoutComplete', callOrder);
        packery.current.off('dragItemPositioned', callOrderAndReload);

        packery.current.on('layoutComplete', callOrder);
        packery.current.on('dragItemPositioned', callOrderAndReload);
        
    }

    function callOrderAndReload() {
        orderItems(isPhotosReloaded.current)
    }

    function callOrder() {
        orderItems(true)
    }

    function orderItems(reload) {
        if (isGridInitialized.current == true) {

            setTimeout(() => {

                const itemElems = packery.current.getItemElements();
                itemElems.forEach((itemElem, i) => {

                    itemElem.id = 'cont-' + (i + 1);
                    itemElem.childNodes[1].id = 'img-' + (i + 1);

                });

                if (itemElems && !reload) {

                    packery.current.reloadItems()
                    packery.current.layout()
                    isPhotosReloaded.current = true

                }

            }, 50);
        }
    }

    // adding preview image from moodBoard
    function AddPhoto(gridProps) {

        const { imageGridSize, download_location, user, url, alt, id, comments } = gridProps.imageData

        const zoomIcon = useRef();

        const image = useRef();

        const pckry = gridProps.pckry;

        const [imageInfo, setImageInfo] = useState();

        useEffect(() => {
            setImageInfo(JSON.stringify({
                id: id,
                alt: alt,
                url: url,
                user: user,
                alreadyDownloaded: true,
                download_location: download_location,
                imageGridSize: imageGridSize,
                comments: comments
            }));
        }, [props.previewMoodBoard]);

        function onHoverOn() {
            zoomIcon.current.classList.add('active')
        }

        function onHoverOut() {
            zoomIcon.current.classList.remove('active')
        }

        function openPreview() {
            props.setPreviewImageFunc(elementRef.current, 'img-'+gridProps.index)
        };

        const elementRef = useRef(null);

        const isElementAddedToGrid = useRef(false);

        useEffect(() => {
            const gridItem = elementRef.current;
            if (gridItem && pckry && isGridInitialized.current == true && !isElementAddedToGrid.current) {
                let draggieImg = new Draggabilly(gridItem);
                pckry.bindDraggabillyEvents(draggieImg);
                pckry.addItems(gridItem);
                isElementAddedToGrid.current = true;
            };
        }, [pckry]);

        const [touchStartTime, setTouchStartTime] = useState(null);

        // for touchscreens
        const handleTouchStart = (e) => {
            const currentTime = new Date().getTime();

            if (touchStartTime && currentTime - touchStartTime < 300) {
            // Double-tap detected
            handleDoubleClick(e);
            setTouchStartTime(null); // Reset touch start time
            } else {
            // Single-tap detected
            setTouchStartTime(currentTime);
            }
        };
        
        const handleDoubleClick = (e) => {
            props.setPreviewImageFunc(elementRef.current, 'img-'+gridProps.index)
        };

        return (
            <div className='grid-item-cont' onMouseOver={onHoverOn} onMouseOut={onHoverOut} ref={elementRef} data-image={imageInfo} key={id}>
                <div className="bigger-icon" ref={zoomIcon} onClick={openPreview}>
                    <svg viewBox="0 0 24.795 25.002" fill='white' height='20px'>
                        <path d="M16.286 0v1.651h5.69L14.938 8.69l1.168 1.168 7.037-7.038v5.69h1.652V0zM8.509 23.144h-5.69l7.038-7.039-1.168-1.167-7.038 7.038v-5.69H0v8.509h8.509z"></path>
                    </svg>
                </div>
                <img src={url} 
                    alt={alt} 
                    data-user={JSON.stringify(user)}
                    className='switch-photo' 
                    id={'img-'+gridProps.index} 
                    onDoubleClick={handleDoubleClick} 
                    onTouchStart={handleTouchStart}
                    ref={image}
                />
                {comments[0] ? <div className='comment-icon'>
                    <img src={CommentIcon} alt="Comment icon" />
                </div> : null}
            </div>
        )
    }

    const [renderArray, setRenderArray] = useState([]);

    useEffect(() => {
        callOrder()
        if (isPhotosReloaded.current = true) {
            isPhotosReloaded.current = false
            callOrderAndReload()
        }
    }, [renderArray])

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        // when we render first time
        if (props.previewMoodBoard && !renderArray[0]) {

            let PhotosList = [];

            let indexIdImage = 0;

            let timeBeforeLayout = 0;
    
            const urlArrayLength = Array.from(props.previewMoodBoard.photos).length;
    
            const sortedOrderArray = props.previewMoodBoard.photos.sort(function(a, b) {
                return a.order - b.order;
            });
            
            for (let i = 0; i < urlArrayLength; i++) {
                indexIdImage++
                PhotosList.push(<AddPhoto previewMoodBoard={props.previewMoodBoard} imageData={sortedOrderArray[i]} key={indexIdImage+"-packery-item"} index={indexIdImage} pckry={packery.current} />)
                timeBeforeLayout += 0.3;
            };
    
            setRenderArray(PhotosList)

            setTimeout(() => {
                packery.current.layout()
                setLoading(false)
                getHeight()
            }, timeBeforeLayout * 1000)

        // if images are already rendered
        } else if (props.previewMoodBoard && renderArray[0]) {

            const allMoodBoardImages = document.querySelectorAll('.grid-item-cont');

            props.previewMoodBoard.photos.forEach((item, i) => {
                const parsedData = JSON.parse(allMoodBoardImages[i].dataset.image);

                if (parsedData.id === item.id) {
                    // console.log(parsedData.comments)
                    // console.log(item.comments)
                    // if (!parsedData.comments[0] && item.comments[0]) {
                    //     console.log('new comment!')
                    // }
                    allMoodBoardImages[i].dataset.image = JSON.stringify(item);
                }
            });

        } else if (!props.previewMoodBoard) {
            setRenderArray(
                <div className="MB-preview-not-found">
                    <h1 className="MB-tag-preview-not-found">This MoodBoard does not exist</h1>
                </div>
            )
        }
    }, [props.previewMoodBoard])

    return (
        <>
            {loading ? (<div className="preview-loader">
                <PreviewLoadingScreen loading={loading} />
            </div>) : null}
            <div className="preview-grid-column"></div>
            <div className="preview-grid-gutter"></div>
            {renderArray}
        </>
    )
}

export {CreatePhotosInGrid}